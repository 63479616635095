
 .footer-container{

    position: relative;
 }
  .footer-container>hr{
    border: 1px solid var(--lightgray);
  }
   .footer{
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
   }
    .social-links{
        display: flex;
        gap: 4rem;
    }
     .social-links>img{
        height: 2rem;
        width: 2rem;
     }
      .logo-f>img{
        width: 10rem;
      }
       .footer-blur-1{
        bottom: 0;
        width: 26rem;
        height: 12rem;
        right: 15%;
        background: red;
        filter: blur(200px);
       }
       .footer-blur-2{
        bottom: 0;
        width: 26rem;
        height: 12rem;
        filter: blur(200px);
        left: 15%;
        background: rgb(255, 155, 0);
       }