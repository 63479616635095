   .programs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
   }.program-header{
    display: flex;
   gap:5rem;
    font-size: 3rem;
    justify-content: center;
    font-style: italic;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
   }
    .program-categories{
      display: flex;
      gap: 1rem;
      padding: 0 2rem;
    }
     .category{
      display: flex;
      flex-direction: column;
      gap: 1rem;
  background:gray;
  padding: 2rem;
 justify-content: space-between;
color: white;
     }
      .category>:nth-child(1){
        height: 2rem;
        width: 2rem;
        fill: white;
      }
      .category>:nth-child(2){
        font-weight: bold;
        font-size: 1rem;
      }
      .category>:nth-child(3){
        font-size: 0.9rem;
        line-height: 25px;
      }

       .join-now{
        display: flex;
        gap: 2rem;
        align-items: center;
       }
        .join-now>img{
          width: 1rem;
        }
         .category:hover{
          background: var(--planCard);
          cursor: pointer;
         }
         @media screen and (max-width: 768px) {
          .program-header{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            font-size: x-large;
            align-items: center;
            justify-content: center;
            margin-top: 2rem;
          }
          .program-categories{
            flex-direction: column;
          }
         }