.hero{
  display: flex;
  justify-content: space-between;
  position: relative;
}
 .left-h{
  padding: 2rem;
  padding-top: 1.5rem;
  flex: 3;
  display: flex;
  gap: 2rem;
  flex-direction: column;
 }
  .right-h{
    flex: 1;
    background: var(--orange);
    position: relative;
  }
   .the-best-ad{
   margin-top: 4rem;
   background: #363d42;
   width: fit-content;
   padding: 20px 13px;
   border-radius: 4rem;
   color: white;
   text-transform: uppercase;
   position: relative;
   display: flex;
   align-items: center;
   justify-content: flex-start;
   }
    .the-best-ad>div{
       position: absolute;
       background: var(--orange);
       height: 80%;
       width: 7rem;
       border-radius: 3rem;
       z-index: 1;
       left: 8px;
    }

     .the-best-ad>span{
      z-index: 2;
     }
      .hero-text{
        font-size: 4.5rem;
        font-weight: bold;
        color: white;
        display: flex;
        gap:1rem;
        flex-direction: column;
        text-transform: uppercase;
      }
       .hero-text>div:nth-of-type(3){
        font-size: 1rem;
        font-weight: normal;
        text-decoration: none;
        text-transform: none;
        letter-spacing: 1px;
        width: 80%;
       }
        .figures{
          display: flex;
          gap: 2rem;
        }
         .figures>div{
          display: flex;
          flex-direction: column;
         }
          .figures>div>span:nth-of-type(1){
            font-size: 2rem;
            color: white;
          }
          .figures>div>span:nth-of-type(2){
            color: var(--gray);
            text-transform: uppercase;
          }
           .buttons{
            display: flex;
            gap: 1rem;
            font-weight: normal;
           }
            .buttons>.btn:nth-of-type(1){
              width: 8rem;
              color: white;
              background: var(--orange);
            }
            .buttons>.btn:nth-of-type(2){
              width: 8rem;
              background: transparent;
              color: white;
              border: 2px solid var(--orange);
            }
             .right-h>.btn{
              position: absolute;
              top: 2rem;
              right: 3rem;
             }
              .heart-rate{
                display: flex;
                flex-direction: column;
                width: fit-content;
                background: var(--darkGrey);
                position: absolute;
                gap: 1rem;
                padding: 1rem;
                align-items: flex-start;
                border-radius: 5px;
                right: 4rem;
                top: 7rem;
              }
               .heart-rate>img{
                   width: 2rem;
               }
                .heart-rate>:nth-child(2){
                  font-size: 1rem;
                  color: var(--gray);
                }
                .heart-rate>:nth-child(3){
                  font-size: 1.5rem;
                  color: var(--gray);
                  color: white;
                }
                 .hero_img{
                  width: 23rem;
                  position: absolute;
                  right: 8rem;
                  top: 10rem;
                 }
                  .hero_img_back{
                    width: 15rem;
                    position: absolute;
                    top: 4rem;
                    right: 20rem;
                    z-index: -1;
                  }
                   .calories{
                    display: flex;
                    gap: 2rem;
                    background: var( --caloryCard);
                    padding: 1rem;
                    position: absolute;
                    top: 32rem;
                    right: 28rem;
                    width: fit-content;
                    border-radius: 5px;
                   }
                    .calories>img{
                      width: 3rem;
                    }
                    .calories>div{
                      display: flex;
                      flex-direction: column;
                     justify-content: space-between;
                    }
                     .calories>div>:nth-of-type(1){
                      color: var(--gray);
                     }
                     .calories>div>:nth-of-type(2){
                      color:white;
                      font-size: 1.5rem;
                     }
                      .hero-blur{
                        width: 22rem;
                        height: 30rem;
                        left: 0;
                      }
                      @media screen and (max-width: 768px) {
                        .hero{
                          flex-direction: column;
                        }
                        .hero-blur{
                          width: 14rem;
                        }
                        .the-best-ad{
                          margin-top: 0;
                          font-size: small;
                          transform: scale(0.8);
                          align-self: center;
                        }
                        .hero-text{
                          font-size: xx-large;
                          align-items: center;
                          justify-content: center;
                        }
                        .hero-text>div:nth-of-type(3){
                          letter-spacing: 1px;
                          font-size: small;
                          font-weight: 200;
                          text-align: justify;
                        }
                        .figures>div>span:nth-of-type(1){
                          font-size: large;
                        }
                        .figures>div>span:nth-of-type(2){
                          font-size: small;
                        }
                        .right-h{
                          position: relative;
                          background: none;
                        }
                        .heart-rate{
                          top: 2rem;
                          left: 1rem;
                        }
                        .calories{
                          position: relative;
                          top: 5rem;
                          left: 2rem;
                        }
                        .calories>div>:nth-child(2){
                          font-size: 1rem;
                        }
                        .calories>img{
                          width: 2rem;
                        }
                        .hero_img{
                          position: relative;
                          width: 15rem;
                          left: 7rem;
                          top: 4rem;
                          align-self: center;
                        }
                        .hero_img_back{
                          width: 15rem;
                          top: 0;
                          left: 2rem;
                        }
                       }
                       