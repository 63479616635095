.testimonials{
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
}
 .left-t{
    display: flex;
    flex: 1 1;
    gap: 2rem;
    flex-direction: column;
 }
  .left-t>:nth-child(1){
    color: var(--orange);
    font-weight: bold;
  }
   
  .left-t>:nth-child(2),
  .left-t>:nth-child(3){
    font-size: 3rem;
   font-weight: bold;
   text-transform: uppercase;
  }
  .left-t>:nth-child(4){
    letter-spacing: 2px;
    line-height: 40px;
    text-align: justify;
    text-transform: none;
  }
   .right-t{
    flex:1;
    position: relative;
   }
    .right-t>img{
        width: 17rem;
        height: 20rem;
        right: 8rem;
        top: 2rem;
        position: absolute;
        object-fit: cover;
    }
     .right-t>:nth-child(1){
        width: 17rem;
        height: 20rem;
        position: absolute;
        background: transparent;
        border: 2px solid var(--orange);right: 9rem;
        top: 0.9rem;
     }
     .right-t>:nth-child(2){
        width: 17rem;
        height: 19rem;
        background: var(--planCard);
        position: absolute;
        top: 4rem;
        right: 7rem;
     }
     .arrows{
        position: absolute;
        bottom: 1rem;
        left: 3rem;
        display: flex;
        gap: 1rem;
     }
     .arrows>img{
        width: 1.5rem;
        cursor: pointer;
     }
     @media screen and (max-width: 768px) {
      .testimonials{
         flex-direction: column;
      }
      .left-t>:nth-child(2),
      .left-t>:nth-child(3){
         font-size: xx-large;
      }
      .right-t{
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         gap: 2rem;
      }
      .right-t>div{
         position: relative;
      display: none;
      }
      .right-t>img{
         position: relative;
         top: 0;
         right: 0;
         align-self:center;
      }
      .right-t>:last-child{
         display: block;
         top: 0;
         bottom: 0;
         left: 1rem;
      }
     }