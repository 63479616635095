.logo{
  width: 10rem;
  height: 3rem;
  position: relative;
}
 .header{
  display:flex;
justify-content: space-between;
position: relative;
 }
  .header-menu{
    display: flex;
    list-style: none;
    gap:2rem;
    color: white;
  }
  .header-menu>li:hover{
    cursor: pointer;
    color: var(--orange);
  }
  @media screen and (max-width: 768px){
    .header>:nth-child(2){
      position: fixed;
      z-index: 99;
      right: 2rem;
    }
    .header-menu{
      flex-direction: column;
      background: var(--appColor);
      padding: 2rem;
    }
  }