
 .plans-container{
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding: 0 2rem;
    position: relative;
 }
  .program-header{
    text-transform: uppercase;
  }
   .plans{
    display: flex;
align-items: center;
justify-content: center;
gap: 3rem;
   }
    
    .plan{
        display: flex;
        flex-direction: column;
        gap: 2rem;
        background: var(--caloryCard);
        padding: 1.5rem;
        color: white;
        width: 15rem;
    }
     .plan:nth-child(2){
       transform: scale(1.1);
       background: var(--planCard);
     }
      .plan>svg{
        width: 2rem;
        height: 2rem;
        fill: var(--orange);
      }
       .plan>:nth-child(2){
        font: 1rem;
        font-weight: bold;
       }
       .plan>:nth-child(3){
        font-size: 3rem;
        font-weight: bold;
       }
     .plan>:nth-child(5){
font-size: 0.8rem;
     }
     .features{
        display:flex;
        flex-direction: column;
        gap: 1rem;
     }
      .feature{
        display: flex;
        gap: 1rem;
        align-items: center;
      }
       .feature>img{
        width: 1rem;
       }
.plans>:nth-child(2)>svg{
fill: white;
}
.plans>:nth-child(2)>button{
    color: var(--orange);
}
 .plan-blur-1{
  width: 32rem;height: 23rem;
  left: 0rem;
top: 6rem;

 }
 .plan-blur-2{
  width: 32rem;height: 23rem;
  right: 0;
 top: 10rem;
 }
 @media screen and (max-width: 768px) {
  .plans{
    flex-direction: column;
  }
  .plans>:nth-child(2){
    transform: none;
  }
 }