
 .join{
    display: flex;
    gap: 10rem;
    padding: 0 2rem;
 }
  
  .left-j{
      font-size: 3rem;
      color: white;
      font-weight: bold;
      text-transform: uppercase;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 1rem;
  }
   .left-j>hr{
    position: absolute;
    margin: -10px 0;
    width: 10.5rem;
    border-radius: 20%;
    border: 2px solid var(--orange);
   }
   .right-j{
    display:flex;
    justify-content: center;
    align-items: flex-end;
   }
    .email-container{
        display: flex;
        gap: 3rem;
        background: gray;
        padding: 1rem 1.5rem;
    }
     .email-container>input{
        background: transparent;
        outline: none;
        border: none;
        color: var(--lightgray);
     }
      ::placeholder{
        color: var(--lightgray);
      }
      .btn-j{
        background: var(--orange);
        color: white;
      }
      @media screen and (max-width: 768px) {
        .join{
          flex-direction: column;
          gap: 1rem;
        }
        .left-j{
          font-size: x-large;
          text-align: center;
          flex-direction: column;
        }
        .right-j{
          padding: 2rem;
        }
      }